import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import TablePagination from "../components/TablePagination";
import api from "../helper/api";
import { ValidateFields } from "./validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import { getImageURL, getServerURL } from "../helper/envConfig";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import Loader from "./loader/Loader";
import slugify from '../helper/slugify'
import SingleError from "../helper/SingleError";
const requireField = ["image", "title", "type", "subscriptionStatus"];
const requireField1 = ["image", "title", "type", "subType", "subscriptionStatus"];

const TrackingContent = ({ temp, search, searchTemp }) => {

  const navigate = useNavigate();
  const serverURL = getServerURL();
  const url = getImageURL();
  const fileInputRef = useRef(null);

  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const addModelOpenFun = () => setAddModel(true);
  const addModelCloseFun = () => setAddModel(false);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  const [deleteDataID, setDeleteDataID] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [totalPages, SetTotalPages] = useState();

  const [trackingList, setTrackingList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [editTrackingData, setEditTrackingData] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [mainLoader, setMainLoader] = useState(true);

  useEffect(() => {
    getTrackingList();
  }, []);

  useEffect(() => {
    getTrackingList();
  }, [page]);

  useEffect(() => {
    if (temp || Boolean(search)) {
      getTrackingList();
    }
    if (searchTemp && !search) {
      getTrackingList();
    }
  }, [temp, search]);

  useEffect(() => {
    if (search?.length) setPage(1);
  }, [search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editTrackingData,
        [name]: value,
      });
      validationErrors = ErrorFilter(validationErrors, editTrackingData?.type === "tracking" ? requireField1 : requireField);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    setEditTrackingData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setEditTrackingData({
        ...editTrackingData,
        [e.target.name]: e.target.files[0],
      });
    }
  };

  const getTrackingList = () => {
    try {
      api
        .getWithToken(
          `${serverURL}tracking-get?page=${page}&limit=${limit}&search=${search}`
        )
        .then(async (res) => {
          // console.log(res);
          setMainLoader(false);
          if (res?.data?.success === true) {
            setTrackingList(res?.data?.data);
            setPaginationData(res?.data?.pagination);
          }
        })
        .catch((e) => {
          setMainLoader(false);
          // console.log(e);
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            setTrackingList([]);
          }
        });
    } catch (error) {
      setMainLoader(false);
      // console.log(error);
    }
  };

  const deleteTracking = () => {
    try {
      api
        .deleteWithToken(
          `${serverURL}tracking-delete/${deleteDataID}`,
        )
        .then(async (res) => {
          // console.log(res);
          setDeleteDataID("");
          getTrackingList();
          if (trackingList?.length == 1) {
            setTrackingList([]);
          }
          deleteModelCloseFun();
          if (res?.data?.success === true) {
            if (typeof res?.data?.message === "string")
              toast.success(res?.data?.message);
            else toast?.success("Tracking deleted successfully");
          }
        })
        .catch((e) => {
          errorResponse(e);
        });
    } catch (error) { }
  };

  const editTracking = () => {
    // Trim leading and trailing spaces from title and description
    const updatedValues = { 
        ...editTrackingData,
        title: editTrackingData?.title?.trim() || "", // Ensure title is not null or undefined
        description: editTrackingData?.description?.trim() || "" // Ensure description is not null or undefined
    };
    
    // Validate fields
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, editTrackingData?.type === "tracking" ? requireField1 : requireField);
    setErrors(validationErrors);

 

    // Scroll into view for the first validation error
    if (Object.keys(validationErrors).length) {
        Object.entries(validationErrors).forEach(([key], i) => {
            if (i === 0) {
                const element = document.getElementById(key);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }
        });
    }

    // Proceed if no validation errors
    if (Object.keys(validationErrors).length === 0) {
        setLoading(true);
        const formData = new FormData();
        formData.append("title", updatedValues.title);
        formData.append("description", updatedValues.description);
        formData.append("image", editTrackingData?.image);
        formData.append("type", editTrackingData?.type);
        formData.append("subscriptionStatus", editTrackingData?.subscriptionStatus);
        if (editTrackingData?.type === "tracking") {
            formData.append("subType", editTrackingData?.subType);
        }
        
        try {
            api.patchWithToken(`${serverURL}tracking-update/${editTrackingData?._id}`, formData)
                .then(async (res) => {
                    setLoading(false);
                    if (res?.data?.success === true) {
                        getTrackingList();
                        editModelCloseFun();
                        setSelectedImage("");
                        toast.success(res?.data?.message || "Lecture Updated successfully");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    errorResponse(e);
                });
        } catch (error) {
            setLoading(false);
        }
    }
};



  function handleNavigate(title, item) {
    navigate(`/tracking/${slugify(title)}`, {
      state: { title, item },
    });
  }

  return (
    <>
      {Boolean(mainLoader) ? (
        <Loader />
      ) : Boolean(trackingList?.length) ?
        <>
          <div className="card mt-3 px-4 pb-4 pt-2">
            <div className="row">
              {Array.isArray(trackingList) &&
                Boolean(trackingList?.length) &&
                trackingList?.map((item, index) =>
                  <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 mt-3 cursor-pointer" key={index + "tracking"} onClick={() => handleNavigate(item?.title, item)}>
                    <div className="card lectureView border-0">
                      <img
                        src={url + item?.image}
                        alt="img"
                        height={"345.512px"}
                      />
                      <div className="lecture-box">
                        <div className="lectureBtn">
                          <div className="w-100">
                            <div className="d-flex justify-content-between">
                              <div className="trackingTitle wordBreak1 pb-0" title={item?.title}>
                                {item?.title}
                              </div>
                              <div className="lectureIcon align-items-center">
                                <img
                                  src="/images/svg/editWhite.svg" title="Edit"
                                  className="px-2 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    editModelOpenFun();
                                    setEditTrackingData(item);
                                  }}
                                />
                                <img
                                  src="/images/svg/deleteWhite.svg" title="Delete"
                                  className="px-2 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deleteModelOpenFun(item?._id);
                                    setDeleteDataID(item?._id);
                                  }}
                                />
                                <img
                                  src={item?.type === "learning" ? "/images/svg/addWhite.svg" : "/images/svg/view.svg"} title={item?.type === "learning" ? "Add" : "View"}
                                  className="pl-2 cursor-pointer"
                                  onClick={() =>
                                    handleNavigate(item?._id, item)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </>
        : (
          <div className="mt-5 w-100 p-5 d-flex justify-content-center">
            <h4 className="test-center">No Records Found.</h4>
          </div>
        )}

      <div className="d-md-flex justify-content-between align-items-center mt-4 mb-3">
        <button
          className="back-button text-decoration-none border-0 text-center"
          onClick={() => navigate("/")}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="2em"
            width="2em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
          </svg>{" "}
          &nbsp; Back
        </button>

        {Boolean(trackingList?.length) && paginationData?.totalPages > 1 && (
          <TablePagination
            currentPage={paginationData?.currentPage}
            totalPages={paginationData?.totalPages}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
          />
        )}
      </div>

      {/* Edit modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit Tracking</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <div className="position-relative my-4">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={
                    selectedImage
                      ? selectedImage
                      : editTrackingData?.image
                        ? url + editTrackingData?.image
                        : "/images/png/duser.png"
                  }
                  onClick={handleImageClick}
                  className={`cursor-pointer ${selectedImage
                    ? "editprofileImage"
                    : editTrackingData?.image
                      ? "editprofileImage"
                      : "profileImageSize"
                    } `}
                // className="editprofileImage cursor-pointer"
                />
              </div>

              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                name="image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />

              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt=""
              />
            </div>
          </Row>

          <Row>
            <Col md={12}>
              <label id="title">Title <span className="star">*</span></label>
              <input
                className="form-control px-3"
                placeholder="Enter tracking title here"
                type="text"
                name="title"
                value={editTrackingData?.title || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.title} />
            </Col>
            <Col md={12}>
              <label id="description">Description</label>
              <textarea
                rows={3}
                className="form-control px-3 fs-20"
                placeholder="Description"
                type="text"
                name="description"
                value={editTrackingData?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>
            <Col md={12}>
              <label id="type">
                Type <span className="star">*</span>
              </label>
              <select className="form-select"
                value={editTrackingData?.type || ""}
                name="type"
                onChange={handleChange}
                disabled
                style={{ color: editTrackingData?.type ? "#000" : "" }}>
                <option selected disabled value={""}>Select type</option>
                <option value={"tracking"}>Tracking</option>
                <option value={"learning"}>Learning</option>
              </select>
              <SingleError error={errors?.type} />
            </Col>

            {editTrackingData?.type === "tracking" &&
              <Col md={12}>
                <label id="type">
                  Sub Type <span className="star">*</span>
                </label>
                <select className="form-select"
                  value={editTrackingData?.subType || ""}
                  name="subType"
                  disabled
                  onChange={handleChange}
                  style={{ color: editTrackingData?.subType ? "#000" : "" }}>
                  <option selected disabled value={""}>Select type</option>
                  <option value={"weight"}>Weight</option>
                  <option value={"stress"}>Stress</option>
                  <option value={"menstrual-cycle"}>Menstrual Cycle</option>
                  <option value={"water-glass"}>Water Glass</option>
                  <option value={"push-up"}>Push Up</option>
                  <option value={"sugar-fast"}>Sugar Fast</option>
                  <option value={"plant-powered"}>Plant Powered</option>
                  <option value={"virtual-fitness"}>Virtual Fitness</option>
                  <option value={"fitness-counter"}>Fitness Counter</option>
                </select>
                <SingleError error={errors?.subType} />
              </Col>
            }
            <Col md={12}>
              <label id="subscriptionStatus">
                Subscription Status <span className="star">*</span>
              </label>
              <select className="form-select"
                value={editTrackingData?.subscriptionStatus || ""}
                name="subscriptionStatus"
                onChange={handleChange}
                style={{ color: editTrackingData?.subscriptionStatus ? "#000" : "" }}>
                <option selected disabled value={""}>Select subscription status</option>
                <option value={"paid"}>Paid</option>
                <option value={"unpaid"}>Unpaid</option>
              </select>
              <SingleError error={errors?.subscriptionStatus} />
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${Boolean(loading) && "btnDisable"
                }`}
              disabled={Boolean(loading)}
              onClick={() => {
                editTracking();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Save"}
            </button>
          </div>

        </Modal.Body>
      </Modal>

      {/* Delete user modal */}
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete Tracking</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28 fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button
              className="modalDeleteBtn border-0"
              onClick={() => deleteTracking()}
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TrackingContent;
