import React, { useState, useEffect, useRef, useCallback } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import api from "../helper/api";
import { getServerURL, getImageURL } from "../helper/envConfig";
import { ValidateFields } from "../components/validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "../redux/action/LoginAction";
import Loader from "./loader/Loader";
import io from "socket.io-client";
import { decryptFromStoreLoc } from "../helper/localStorage";
import user_icon from "../../src/assets/images/user-svgrepo-com.svg";
import remove_icon from "../../src/assets/images/remove-ellipse-svgrepo-com.svg";
import SingleError from "../helper/SingleError";
import TablePagination from "./TablePagination";
import { debounce } from "lodash";
const ChannelContent = ({
  temp,
  search,
  searchTemp,
  setSearch,
  setSearchTemp,
}) => {
  const [editModel, setEditModel] = useState(false);
  const [imageModel, setImageModel] = useState(false);
  const [currentImage, setCurrentImage] = React.useState(null);
  const [deleteModel, setDeleteModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [channelList, setChannelList] = useState([]);

  const [userList, setUserList] = useState([]);
  const [replyuserList, setReplyUserList] = useState([]);

  const [paginationData, setPaginationData] = useState({});

  const [mainLoader, setMainLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const serverURL = getServerURL();
  const [selectedImage, setSelectedImage] = useState(null);
  const [messselectedImage, setMessSelectedImage] = useState("");

  const [replymessselectedImage, setReplyMessSelectedImage] = useState([]);

  const [refreshData, setRefreshData] = useState(false);
  const profile = useSelector((item) => item?.login);
  const [profileData, setProfileData] = useState(profile || {});
  const [submitCount, setSubmitCount] = useState(0);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [replymessage, setReplyMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [replies, setReplies] = useState({});
  const [replyData, setReplyData] = useState({});
  const [profile_img, setProfile_Img] = useState("");
  const [profile_name, setProfile_Name] = useState("");
  const [adminId, setAdminId] = useState("");

  const [isReplying, setIsReplying] = useState(false);
  const [replyMessageId, setReplyMessageId] = useState(null);
  const [isUserListVisible, setUserListVisible] = useState(false);
  const [isMessUserListVisible, setMessUserListVisible] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState(userList);
  const [replyfilteredUsers, setReplyFilteredUsers] = useState(userList);

  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    name: "",
    description: "",
    image: null,
  });
  const imageUrl = getImageURL();
  const requiredFields = ["image", "name", "description"];
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const messagesEndRef = useRef(null);
  
  const userListRef = useRef(null);
  const replyuserListRef = useRef(null);
  const replytextareaRef = useRef(null);
  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_BASE_URL_PRODUCTION);
    setSocket(newSocket);

    // Handle incoming messages
    const handleIncomingMessages = (data) => {
      if (selectedItem && selectedItem?._id) {
        setLoadingMessages(true);
        const filteredMessages = data.data.filter(
          (msg) => msg.groupId === selectedItem?._id
        );
        setMessages(filteredMessages);  // Update the messages state
        setLoadingMessages(false);
      }
    };
  
    // Handle incoming replies
    const handleIncomingReplies = (data) => {
      data.data.forEach((reply) => {
        setReplyData((prev) => {
          const existingReplies = prev[reply.messageId] || [];
          const isDuplicate = existingReplies?.some(
            (r) => r?._id === reply?._id
          );

          if (!isDuplicate) {
            return {
              ...prev,
              [reply.messageId]: [...existingReplies, reply],
            };
          }
          return prev;
        });
      });
    };
  
    // Fetch initial messages when component mounts or selectedItem changes
    const fetchInitialMessages = () => {
      if (selectedItem && selectedItem?._id) {
        setMessages([]);  // Clear the previous messages
        setReplyData({});  // Clear the reply data
        newSocket.emit("getGroupMessages", { token, groupId: selectedItem?._id });
      }
    };
  
    // Listen for 'getGroupMessages' and 'getGroupMessagesReplies' events
    newSocket.on("getGroupMessages", handleIncomingMessages);
    newSocket.on("getGroupMessagesReplies", handleIncomingReplies);
  
    // Fetch messages on mount or selectedItem change
    fetchInitialMessages();
  
    // Clean up on component unmount
    return () => {
      newSocket.off("getGroupMessages", handleIncomingMessages);
      newSocket.off("getGroupMessagesReplies", handleIncomingReplies);
      newSocket.close();
    };
  }, [selectedItem, token]);
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]); // Add dependencies that trigger scrolling

  useEffect(() => {
    getProfileData();
  }, []);
  
  const getProfileData = () => {
    api
      .postWithToken(`${serverURL}profile-view`)
      .then(async (res) => {
        setMainLoader(false);
        if (res?.data?.success === true) {
          const profileData = res?.data?.data;
          dispatch(setLogin(profileData));
  
          const profileImage = profileData.profile_image;
          const adminId = profileData._id;
  
          setProfile_Name( profileData.first_name);
          console.log("profile>>>",profileData.first_name);
          
          setAdminId(adminId);
          setProfile_Img(profileImage);
        }
      })
      .catch((e) => {
        console.log(e);
        setMainLoader(false);
      });
  };
  const handleReplyToggle = (messageId) => {
    if (replies[messageId]) {
      setReplies((prev) => ({
        ...prev,
        [messageId]: !prev[messageId],
      }));
    } else {
      socket.emit("getGroupMessagesReplies", {
        token,
        groupId: selectedItem?._id,
        messageId,
      });
      setReplies((prev) => ({
        ...prev,
        [messageId]: true,
      }));
    }
  };
  const removeSelectedImage = (index) => {
    setMessSelectedImage((prevImages) =>
      prevImages.filter((_, i) => i !== index)
    );
  };
  const removeReplySelectedImage = (index) => {
    setReplyMessSelectedImage((prevImages) =>
      prevImages.filter((_, i) => i !== index)
    );
  };
  const isProfileDataReady = profile_img && profile_name;
  const handleSubmitMessage = () => {
    const images = messselectedImage || [];
    if (!isProfileDataReady) {
      toast.error("Profile data not available. Please try again.");
      return;
    }
    if (!message.trim() && images.length > 0) {
      if (socket && selectedItem) {
        const currentDate = new Date().toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        const newMessage = {
          message: "",
          groupId: selectedItem?._id,
          created_at: currentDate,
          token: token,
          image: images, // Array of images
          adminId: adminId,
          profile_image: profile_img, // Send the profile image
          username: profile_name, // Send the profile name
        };
  
        setMessages((prevMessages) => [...prevMessages, newMessage]);  // Append the new message
        console.log(newMessage);
        
        socket.emit("sendGroupMessage", newMessage);
        setMessSelectedImage([]);  // Clear selected images
      }
    } else if (message.trim()) {
      if (socket && message && selectedItem) {
        const currentDate = new Date().toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        const newMessage = {
          message: message,
          groupId: selectedItem?._id,
          created_at: currentDate,
          token: token,
          image: images, // Array of images
          adminId: adminId,
          profile_image: profile_img, // Send the profile image
          username: profile_name, // Send the profile name
        };
  
        setMessages((prevMessages) => [...prevMessages, newMessage]);  // Append the new message
        console.log(newMessage);
        
        socket.emit("sendGroupMessage", newMessage);
        setMessage("");  // Clear the message input
        setMessSelectedImage([]);  // Clear selected images
      }
    } else {
      if (message.trim() === "") {
        const errorMessage =
          "Message cannot be empty. Please enter some text or select an image.";
        toast.error(errorMessage);
      }
    }
  };
  const handleSubmitReplyMessage = () => {
    const images = replymessselectedImage || [];

    if (!replymessage.trim() && images.length > 0) {
      if (socket && selectedItem) {
        const currentDate = new Date().toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        const newMessage = {
          message: "",
          groupId: selectedItem?._id,
          created_at: currentDate,
          token,
          image: images,
          messageId: replyMessageId,
          sender_user: {
            profile_image: profile_img,
            username: profile_name,
          },
        };

        setReplyData((prev) => ({
          ...prev,
          [replyMessageId]: [...(prev[replyMessageId] || []), newMessage],
        }));

        // Optimistically update reply count
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg?._id === replyMessageId
              ? { ...msg, totalReplies: (msg.totalReplies || 0) + 1 }
              : msg
          )
        );

        socket.emit("sendGroupMessage", newMessage);
        setReplyMessSelectedImage([]);
        setIsReplying(false);
        setReplyMessageId(null);
      }
    } else if (replymessage.trim()) {
      if (socket && replymessage && selectedItem) {
        const currentDate = new Date().toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        const newMessage = {
          message: replymessage,
          groupId: selectedItem?._id,
          created_at: currentDate,
          token,
          image: images,
          messageId: replyMessageId,
          sender_user: {
            profile_image: profile_img,
            username: profile_name,
          },
        };

        setReplyData((prev) => ({
          ...prev,
          [replyMessageId]: [...(prev[replyMessageId] || []), newMessage],
        }));

        // Optimistically update reply count
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg?._id === replyMessageId
              ? { ...msg, totalReplies: (msg.totalReplies || 0) + 1 }
              : msg
          )
        );

        socket.emit("sendGroupMessage", newMessage);
        setReplyMessage("");
        setReplyMessSelectedImage([]);
        setIsReplying(false);
        setReplyMessageId(null);
      }
    } else {
      if (replymessage.trim() === "") {
        const errorMessage =
          "Reply message cannot be empty. Please enter some text or select an image.";
        toast.error(errorMessage);
      }
    }
  };

  /* Start User Mention Functionality */
  const textareaRef = useRef(null);
  const handleTextareaChanges = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);

    const cursorPos = textareaRef.current.selectionStart;
    const lastAtSymbol = newMessage.lastIndexOf("@", cursorPos - 1);

    if (lastAtSymbol !== -1) {
      const query = newMessage.substring(lastAtSymbol + 1, cursorPos).trim();

      if (query) {
        const filtered = userList.filter((user) =>
          user.username.toLowerCase().startsWith(query.toLowerCase())
        );
        setFilteredUsers(filtered);
        setUserListVisible(true);
      } else {
        setFilteredUsers(userList);
      }

      setUserListVisible(true);
    } else {
      setUserListVisible(false);
    }
  };
  const handleReplyTextareaChanges = (e) => {
    const newMessage = e.target.value;
    setReplyMessage(newMessage);

    const cursorPos = replytextareaRef.current.selectionStart;
    const lastAtSymbol = newMessage.lastIndexOf("@", cursorPos - 1);

    if (lastAtSymbol !== -1) {
      const query = newMessage.substring(lastAtSymbol + 1, cursorPos).trim();

      if (query) {
        const filtered = replyuserList.filter((user) =>
          user.username.toLowerCase().startsWith(query.toLowerCase())
        );
        setReplyFilteredUsers(filtered);
      } else {
        setReplyFilteredUsers(replyuserList);
      }

      setMessUserListVisible(true);
    } else {
      setMessUserListVisible(false);
    }
  };
  const handleToggleUserList = () => {
    setUserListVisible((prev) => {
      const newVisibility = !prev;
      if (newVisibility) {
        // Insert "@" into the message
        const newMessage = message + "@";
        setMessage(newMessage);

        // Focus the textarea and place the cursor at the end
        textareaRef.current.focus();
        textareaRef.current.setSelectionRange(
          newMessage.length,
          newMessage.length
        );

        // Set the filtered users based on the new message
        setFilteredUsers(userList);
      }
      return newVisibility;
    });
  };
  const handleToggleReplyUserList = () => {
    setMessUserListVisible((prev) => {
      const newVisibility = !prev;
      if (newVisibility) {
        // Insert "@" into the message
        const newMessage = replymessage + "@";
        setReplyMessage(newMessage);

        // Focus the textarea and place the cursor at the end
        replytextareaRef.current.focus();
        replytextareaRef.current.setSelectionRange(
          newMessage.length,
          newMessage.length
        );

        // Set the filtered users based on the new message
        setReplyFilteredUsers(replyuserList);
      }
      return newVisibility;
    });
  };
  const handleUserSelect = (user) => {
    const cursorPos = textareaRef.current.selectionStart;
    const lastAtSymbol = message.lastIndexOf("@", cursorPos - 1);
  
    // Create the new message with the tagged username
    const newMessage = `${message.substring(0, lastAtSymbol + 1)}${user.username} ${message.substring(cursorPos)}`;
    
    setMessage(newMessage);
    setUserListVisible(false); // Hide the user list
  };
  
  const handleReplyUserSelect = (user) => {
    const cursorPos = replytextareaRef.current.selectionStart;
    const lastAtSymbol = replymessage.lastIndexOf("@", cursorPos - 1);

    const newMessage = `${replymessage.substring(0, lastAtSymbol + 1)}${
      user.username
    } ${replymessage.substring(cursorPos)}`;
    setReplyMessage(newMessage);
    setMessUserListVisible(false); // Hide the user list
  };

  /* End User Mention Functionality */
  const handleReplyButtonClick = (messageId) => {
    // Check if the clicked message ID is the same as the currently set replyMessageId
    if (replyMessageId === messageId) {
      // Toggle isReplying state if the same message is clicked
      setIsReplying(!isReplying);
    } else {
      // Set new message ID and start replying
      setReplyMessageId(messageId);
      setIsReplying(true);
    }
  };




  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userListRef.current && !userListRef.current.contains(event.target)) {
        setUserListVisible(false);
        setMessUserListVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    setFilteredUsers(userList);
  }, [userList]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        replyuserListRef.current &&
        !replyuserListRef.current.contains(event.target)
      ) {
        setMessUserListVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    setReplyFilteredUsers(replyuserList);
  }, [replyuserList]);

  
  const editModelOpenFun = (item) => {
    setSelectedItem(item);
    const updatedState = {
      name: item.name || "",
      description: item.description || "",
      image: item.image || "",
    };
    setState(updatedState);
    setEditModel(true);
  };
  const imageModelOpenFun = (imageUrl) => {
    setCurrentImage(imageUrl);
    setImageModel(true);
  };

  const editModelCloseFun = () => {
    setEditModel(false);
    // Reset the selected image state
    setSelectedImage(null);
    // Optionally, reset other states if needed
    setState((prev) => ({ ...prev, image: null }));
  };

  const imageModelCloseFun = () => setImageModel(false);
  useEffect(() => {
    if (editModel) {
      if (selectedItem) {
        setSelectedImage(imageUrl + selectedItem.image || null);
      }
    }
  }, [editModel, selectedItem]);

  const deleteModelOpenFun = (item) => {
    setSelectedItem(item);
    setDeleteModel(true);
  };

  const deleteModelCloseFun = () => setDeleteModel(false);

  const getUsers = async () => {
    setMainLoader(true);
    try {
      const response = await api.getWithToken(`${serverURL}admin-list-message`);
      if (response?.data?.success) {
        setUserList(response.data.data || []);
        setReplyUserList(response.data.data || []);
      }
    } catch (error) {
      console.error(
        "Error fetching products:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setMainLoader(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [refreshData]);
  const debouncedGetProducts = useCallback(
    debounce(() => {
      setMainLoader(true);
      // API call logic here
      api
        .getWithToken(
          `${serverURL}admin-list-channels?page=${page}&limit=${limit}&search=${search}`
        )
        .then((response) => {
          if (response?.data?.success) {
            const newChannelList = response.data.data || [];
            setChannelList(newChannelList);
            setPaginationData(response?.data?.pagination);
            if (newChannelList.length === 0 && page > 1) {
              setPage(page - 1);
            } else if (newChannelList.length > 0) {
              setId(newChannelList[0]._id);
            }
          } else {
            setChannelList([]);
          }
        })
        .catch((error) => {
          console.error(
            "Error fetching products:",
            error.response ? error.response.data : error.message
          );
        })
        .finally(() => {
          setMainLoader(false);
        });
    }, 1000), // Debounce for 1 second
    [page, limit, search] // Dependencies
  );

  useEffect(() => {
    debouncedGetProducts();
  }, [search, page, limit, refreshData, temp, debouncedGetProducts]);

  const deleteItem = async (itemId) => {
    try {
      const response = await api.deleteWithToken(
        `${serverURL}admin-delete-channels?id=${itemId}`
      );
      if (
        channelList.length === 1 &&
        paginationData?.totalPages > 1 &&
        page > 1
      ) {
        setPage((prevPage) => prevPage - 1);
      } else {
        await debouncedGetProducts();
      }
      await debouncedGetProducts();
      deleteModelCloseFun();

      // Display success message
      const successMessage =
        typeof response?.data?.data?.message === "string"
          ? response?.data?.data?.message
          : "Channel deleted successfully.";
      toast.success(successMessage);
    } catch (error) {
      console.error("Error deleting item:", error.response || error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
    if (submitCount > 0) validateFields({ ...state, [name]: value });
  };
  const validateFields = (updatedValues) => {
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requiredFields);

    if (updatedValues?.description?.trim().length === 0) {
      validationErrors.description = "Description cannot contain only spaces.";
    }
    setErrors(validationErrors);
    return validationErrors;
  };

  const fileInputRef = useRef(null);
  const messfileInputRef = useRef(null);
  const replymessfileInputRef = useRef(null);

  const handleImageClick = () => fileInputRef.current.click();
  const handleMessImageClick = () => messfileInputRef.current.click();
  const handleReplyMessImageClick = () => replymessfileInputRef.current.click();
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setState((prevState) => ({
        ...prevState,
        image: file,
      }));

      // Optionally, update the selectedImage to preview the selected image
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const MesshandleFileChange = (e) => {
    const files = Array.from(e.target.files);

    // Filter out only valid image types
    const validTypes = ["image/png", "image/jpeg"];
    const invalidFiles = files.filter(
      (file) => !validTypes.includes(file.type)
    );

    if (invalidFiles.length) {
      const errorMessage = "Only PNG, JPEG and JPG images are allowed";
      toast.error(errorMessage);
      return;
    }

    if (files.length) {
      const fileReaders = files.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
      });

      Promise.all(fileReaders).then((fileDataUrls) => {
        // Merge new images with existing ones
        setMessSelectedImage((prevImages) => [
          ...prevImages,
          ...fileDataUrls,
        ]);
        
        setState((prev) => ({
          ...prev,
          image: [...(prev.image || []), ...files], // Ensure prev.image is an array
        }));
      });
    }
};



  const ReplyMesshandleFileChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to Array
    // Filter out only valid image types
    const validTypes = ["image/png", "image/jpeg"];
    const invalidFiles = files.filter(
      (file) => !validTypes.includes(file.type)
    );

    if (invalidFiles.length) {
      // If there are invalid files, show an error message
      const errorMessage = "Only PNG, JPEG and JPG images are allowed";
      toast.error(errorMessage);
      return;
    }
    if (files.length) {
      const fileReaders = files.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
      });

      Promise.all(fileReaders).then((fileDataUrls) => {
        setReplyMessSelectedImage(fileDataUrls); // Set array of image URLs
        setState((prev) => ({ ...prev, image: files }));
      });
    }
  };
  const handleFormSubmit = async () => {
    setSubmitCount((count) => count + 1);
  
    // Perform additional validation
    const validationErrors = validateFields(state);
    const trimmedName = (state.name || "").trim();
    const trimmedDescription = (state.description || "").trim();
  
    // Add maximum length validation for channel name
    if (trimmedName.length > 50) {
      validationErrors.name = "Channel name must be less than 50 characters";
    }
  
    // Update the state with trimmed values for submission
    const trimmedState = {
      ...state,
      name: trimmedName,
      description: trimmedDescription,
    };
  
    if (Object.keys(validationErrors).length === 0) 
      {
      await submitRegimen(trimmedState); // Pass trimmed state to submitRegimen
    } 
    else 
    {
      const firstErrorKey = Object.keys(validationErrors)[0];
      document
        .getElementById(firstErrorKey)
        ?.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  const submitRegimen = async (trimmedState) => {
    setLoading(true);
    const formData = new FormData();
    Object.keys(trimmedState).forEach((key) => {
      formData.append(key, trimmedState[key]);
    });
  
    try {
      const response = await api.patchWithToken(
        `${serverURL}admin-update-channels?id=${selectedItem._id}`,
        formData
      );
      if (response?.data?.success) {
        handleSuccess(response);
      }
    } catch (error) {
      errorResponse(error); // Assuming errorResponse is your error handling function
    } finally {
      setLoading(false);
    }
  };
  const handleSuccess = (response) => {
    editModelCloseFun();
    setRefreshData(!refreshData);

    const successMessage =
      typeof response?.data?.data?.message === "string"
        ? response?.data?.data?.message
        : "Channel updated successfully.";
    toast.success(successMessage);
  };
  useEffect(() => {
    if (channelList.length > 0) {
      setSelectedItem(channelList[0]);
      setLoadingMessages(true);
    }
  }, [channelList]);
  useEffect(() => {
    const textarea = textareaRef.current;

    const autosize = () => {
      if (textarea) {
        textarea.style.height = "28px";
        const newHeight = Math.max(textarea.scrollHeight, 28);
        textarea.style.height = `${newHeight}px`;
      }
    };
    if (textarea) {
      textarea.style.height = "28px";
    }
    autosize();
    if (textarea) {
      textarea.addEventListener("input", autosize);
    }
    return () => {
      if (textarea) {
        textarea.removeEventListener("input", autosize);
      }
    };
  }, [message]);

  const renderMessageWithTags = (message) => {
    const tagPattern = /@(\w+)/g; // Capture only @username
  
    const parts = message.split(tagPattern);
  
    return parts.map((part, index) => {
      // If the part matches the tag pattern, highlight it
      if (index % 2 !== 0) {
        return (
          <span key={index} className="tagged-user">
            {part}
          </span>
        );
      }
  
      return <span key={index}>{part}</span>;
    });
  };
  
  const renderUserList = () => {
    if (filteredUsers.length > 0) {
      return filteredUsers.map((user) => (
        <div
          key={user?._id}
          className="user-list-item"
          onClick={() => handleUserSelect(user)}
        >
          {user.username}
        </div>
      ));
    }
    setUserListVisible(false); // Move this out of render
    return null;
  };
  const renderReplyUserList = () => {
    if (replyfilteredUsers.length > 0) {
      return replyfilteredUsers.map((user) => (
        <div
          key={user?._id}
          className="user-list-item"
          onClick={() => handleReplyUserSelect(user)}
        >
          {user.username}
        </div>
      ));
    }
    setMessUserListVisible(false); // Move this out of render
    return null;
  };
  
  return (
    <>
      <Row>
        <Col md={6} sm={12} lg={3}>
          <div className="card p-2">
            <div className="form-control d-flex align-items-center px-3 mb-3 input-bg">
              <img
                src="/images/svg/search.svg"
                className="imput-img"
                alt="search.svg"
              />
              <input
                className="form-control border-0 shadow-none pl-black"
                placeholder="Enter search text here"
                type="text"
                name="search"
                autoComplete="off"
                onChange={(e) => {
                  setSearch(e.target.value);
                  setSearchTemp(true);
                  setPage(1);
                }}
              />
            </div>

            {channelList.length > 0 ? (
              channelList.map((item) => {
                return (
                  <div
                    className={`card p-2 mb-2 ${
                      selectedItem?._id === item._id ? "chat-active" : ""
                    }`}
                    key={item?._id}
                    onClick={() => setSelectedItem(item)}
                  >
                    <div className="user-chat-box d-flex gap-3">
                      <img
                        src={
                          item.image ? `${imageUrl}${item.image}` : user_icon
                        }
                        alt=""
                        className="ml-2 channelUser"
                      />
                      <div style={{ width: "80%" }}>
                        <div className="d-flex align-content-center justify-content-between gap-3">
                          <h5 className="mb-0">{item?.name} </h5>
                          <span className="channel">
                            <img
                              src="/images/svg/channelEdit.svg"
                              className="channelIcon"
                              title="Edit"
                              alt=""
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents click event from bubbling up
                                editModelOpenFun(item);
                              }}
                            />
                            <img
                              src="/images/svg/channelDelete.svg"
                              className="channelIcon"
                              title="Delete"
                              alt=""
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents click event from bubbling up
                                deleteModelOpenFun(item);
                              }}
                            />
                          </span>
                        </div>
                        <h6 className="channelText mb-0 wordBreak">
                          {item?.description}
                        </h6>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p colSpan="3" style={{ textAlign: "center" }}>
                No data available
              </p>
            )}
          </div>

          {Boolean(channelList?.length) && paginationData?.totalPages > 1 && (
            <TablePagination
              currentPage={paginationData?.currentPage}
              totalPages={paginationData?.totalPages}
              onPageChange={(newPage) => {
                setPage(newPage);
              }}
            />
          )}
        </Col>
        {
          channelList.length>0 &&(
            <Col sm={12} md={6} lg={9} className="mt-4 mt-md-0">
          <div className="card p-2 position-relative">
            {selectedItem && (
              <div className="d-flex align-items-center p-2 gap-2 msgBox">
                <img
                  src={
                    selectedItem?.image
                      ? `${imageUrl}${selectedItem?.image}`
                      : user_icon
                  }
                  alt=""
                  className="msguser"
                />
                <span className="fs-20 fw-600">{selectedItem?.name}</span>
              </div>
            )}

            <div className="messages-container chat-area-box">
              {loadingMessages ? (
                <Loader />
              ) : messages.length > 0 ? (
                messages.map((msg, index) => (
                  <div key={index} className="card my-2">
                    <div className="d-flex p-3">
                      <img
                        src={
                          msg.profile_image
                            ? imageUrl + msg.profile_image
                            : user_icon
                        }
                        className="channelUser"
                        alt=""
                      />
                      <div className="w-100 pb-5">
                        <div className="d-flex justify-content-between pl-2 mb-3">
                          <span className="msgHead">{profile_name || "-"}</span>
                          <span>
                            {(() => {
                              const date = new Date(msg.created_at);
                              const day = String(date.getDate()).padStart(
                                2,
                                "0"
                              );
                              const month = String(
                                date.getMonth() + 1
                              ).padStart(2, "0"); // Months are 0-indexed
                              const year = date.getFullYear();
                              const hours = String(date.getHours()).padStart(
                                2,
                                "0"
                              );
                              const minutes = String(
                                date.getMinutes()
                              ).padStart(2, "0");
                              const ampm = hours >= 12 ? "PM" : "AM";
                              const formattedHours = hours % 12 || 12; // Convert 24-hour time to 12-hour time

                              return `${day}/${month}/${year}, ${formattedHours}:${minutes} ${ampm}`;
                            })() || "Date not available"}
                          </span>
                        </div>

                        <div
                          className="justify-content-end align-items-start"
                          key={msg._id}
                        >
                          <div className="d-flex justify-content-end pl-2 gap-2 align-items-center">
                            <div
                              className="reply-chip text-nowrap"
                              onClick={() => handleReplyToggle(msg._id)}
                            >
                              <span className="msgreplyText">
                                <span className="replyCount">
                                  {msg.totalReplies} reply
                                </span>
                              </span>
                            </div>
                          </div>
                          {msg.image.length > 0 && (
                            <div className="mt-2">
                              {msg.image.map((img, index) => (
                                <img
                                  key={index}
                                  src={
                                    img.startsWith("data:image/")
                                      ? img
                                      : `${imageUrl}${img}`
                                  }
                                  onClick={() =>
                                    imageModelOpenFun(
                                      img.startsWith("data:image/")
                                        ? img
                                        : `${imageUrl}${img}`
                                    )
                                  }
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    marginRight: "10px",
                                    marginBottom: "10px",
                                    cursor: "pointer",
                                  }}
                                  alt={`Message Image ${index}`}
                                />
                              ))}
                            </div>
                          )}
                        </div>
                        <span className="msgText mt-2">
                          {msg.message && (
                            <p
                              className="pl-2 mt-1"
                              style={{
                                textAlign: "justify",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {renderMessageWithTags(msg.message)}
                            </p>
                          )}
                        </span>

                        {replies[msg._id] && (
                          <div className="replies-section mt-2">
                            {replyData[msg._id]?.length > 0 ? (
                              replyData[msg._id].map((reply, index) => (
                                <div
                                  key={index}
                                  className="reply-card my-2 d-flex justify-content-start"
                                >
                                  <div className="py-3 d-flex align-items-start gap-3 border-top w-100">
                                    <div className="d-flex justify-content-end pt-1">
                                      <img
                                        src={
                                          reply?.sender_user?.profile_image
                                            ? `${imageUrl}${reply.sender_user.profile_image}`
                                            : "/images/png/duser.png"
                                        }
                                        className="channelUser"
                                        alt=""
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="pl-2">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                          <span className="msgHead d-block">
                                            {reply?.sender_user?.username ||
                                              "-"}
                                          </span>
                                          <span className="d-block">
                                            {(() => {
                                              const date = new Date(
                                                reply?.created_at
                                              );
                                              const day = String(
                                                date.getDate()
                                              ).padStart(2, "0");
                                              const month = String(
                                                date.getMonth() + 1
                                              ).padStart(2, "0"); // Months are 0-indexed
                                              const year = date.getFullYear();
                                              const hours = String(
                                                date.getHours()
                                              ).padStart(2, "0");
                                              const minutes = String(
                                                date.getMinutes()
                                              ).padStart(2, "0");
                                              const ampm =
                                                hours >= 12 ? "PM" : "AM";
                                              const formattedHours =
                                                hours % 12 || 12; // Convert 24-hour time to 12-hour time

                                              return `${day}/${month}/${year}, ${formattedHours}:${minutes} ${ampm}`;
                                            })() || "Date not available"}
                                          </span>
                                        </div>
                                        <span>
                                          {reply.image.length > 0 &&
                                          reply.image ? (
                                            reply.image.map((img, index) => (
                                              <img
                                                key={index}
                                                src={
                                                  img.startsWith("data:image/")
                                                    ? img
                                                    : `${imageUrl}${img}`
                                                }
                                                onClick={() =>
                                                  imageModelOpenFun(
                                                    img.startsWith(
                                                      "data:image/"
                                                    )
                                                      ? img
                                                      : `${imageUrl}${img}`
                                                  )
                                                }
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                  objectFit: "cover",
                                                  marginRight: "10px",
                                                  marginBottom: "10px",
                                                  cursor: "pointer",
                                                }}
                                                alt={`Reply Image ${index}`}
                                              />
                                            ))
                                          ) : (
                                            <></>
                                          )}

                                          <p
                                            className="msgText"
                                            style={{ textAlign: "justify" }}
                                          >
                                            {renderMessageWithTags(reply.message)}
                                          </p>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="text-center">
                                No replies available
                              </div>
                            )}

                            <div className="reply-chip set-chip mb-3">
                              <span className="msgreplyText">
                                <span
                                  className="replyCount"
                                  onClick={() =>
                                    handleReplyButtonClick(msg?._id)
                                  }
                                >
                                  Reply back
                                </span>
                              </span>
                            </div>

                            {isReplying && replyMessageId === msg?._id && (
                              <>
                                <div className="d-flex justify-content-between replymsgView px-3">
                                  <div className="d-flex align-items-center gap-2">
                                    <img
                                      src="/images/svg/pin.svg"
                                      alt=""
                                      onClick={handleReplyMessImageClick}
                                      style={{ cursor: "pointer" }}
                                    />
                                    <input
                                      type="file"
                                      ref={replymessfileInputRef}
                                      style={{ display: "none" }}
                                      accept="image/*"
                                      multiple
                                      onChange={ReplyMesshandleFileChange}
                                    />
                                  </div>
                                  <textarea
                                    ref={replytextareaRef}
                                    className="p-1 w-100 text-white fs-16 fw-500 bgMsg border-0 outline-0 position-absolut bottom-0 custom-textarea"
                                    type="text"
                                    placeholder="Add a reply"
                                    name="channel"
                                    value={replymessage}
                                     rows="1"
                                    onChange={handleReplyTextareaChanges}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter" && e.shiftKey) {
                                        // Insert newline on Shift + Enter
                                        e.preventDefault();
                                        setReplyMessage(replymessage + "\n");
                                      } else if (e.key === "Enter") {
                                        // Handle sending message on Enter without Shift
                                        e.preventDefault();
                                        handleSubmitReplyMessage();
                                      }
                                    }}
                                  />

                                  <div className="d-flex align-items-center gap-3">
                                    <span
                                      className="text-white fs-22 fw-500 mess-cursor"
                                      onClick={handleToggleReplyUserList}
                                    >
                                      @
                                    </span>
                                    <img
                                      src="/images/svg/send.svg"
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                      onClick={handleSubmitReplyMessage}
                                    />
                                  </div>
                                  {isMessUserListVisible && (
                                    <div
                                      className="user-list-dropdown"
                                      ref={replyuserListRef}
                                    >
                                    {renderReplyUserList()}
                                    </div>
                                  )}
                                </div>
                                {replymessselectedImage &&
                                  replymessselectedImage.length > 0 && (
                                    <div className="d-flex gap-2 images-wrapper">
                                      {replymessselectedImage.map(
                                        (imgSrc, index) => (
                                          <div className="msg-images">
                                            <img
                                              key={index}
                                              src={imgSrc}
                                              alt={`Selected Preview ${index}`}
                                              style={{
                                                width: "40px",
                                                height: "40px",
                                                objectFit: "cover",
                                              }}
                                            />
                                            <div className="rem-img-icon">
                                              <img
                                                src={remove_icon}
                                                onClick={() =>
                                                  removeReplySelectedImage(
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center mt-5">No messages available</div>
              )}
              <div ref={messagesEndRef} />
            </div>
            <div className="d-flex justify-content-between msgView px-3 position-relative h-auto">
              <div className="d-flex align-items-center gap-2 mess-cursor position-elative">
                <img
                  src="/images/svg/pin.svg"
                  alt=""
                  onClick={handleMessImageClick}
                />
                <input
                  type="file"
                  ref={messfileInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  multiple
                  onChange={MesshandleFileChange}
                />
              </div>
              <textarea
                ref={textareaRef}
                className="p-1 w-100 text-white fs-16 fw-500 bgMsg border-0 outline-0 position-absolut bottom-0 custom-textarea"
                placeholder="Message"
                name="channel"
                value={message}
                onChange={handleTextareaChanges}
                 rows="1"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.shiftKey) {
                    e.preventDefault();
                    setMessage(message + "\n");
                  } else if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmitMessage();
                  }
                }}
              />
              <div className="d-flex align-items-center gap-3">
                <span
                  className="text-white fs-22 fw-500 mess-cursor"
                  onClick={handleToggleUserList}
                >
                  @
                </span>
                {isUserListVisible && (
                  <div className="user-list-dropdown" ref={userListRef}>
                  {renderUserList()}
                  </div>
                )}
                <img
                  src="/images/svg/send.svg"
                  alt=""
                  onClick={handleSubmitMessage}
                  className="mess-cursor"
                />
              </div>
            </div>
            {messselectedImage && messselectedImage.length > 0 && (
              <div className="d-flex gap-2 images-wrapper">
                {messselectedImage.map((imgSrc, index) => (
                  <div className="msg-images">
                    <img
                      key={index}
                      src={imgSrc}
                      alt={`Selected Preview ${index}`}
                      style={{
                        width: "40px",
                        height: "40px",
                        objectFit: "cover",
                      }}
                    />
                    <div className="rem-img-icon">
                      <img
                        src={remove_icon}
                        onClick={() => removeSelectedImage(index)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Col>
          )
        }
      
      </Row>
      {/* Edit user modal */}
      <Modal
        Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit Channel</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="mt-2" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <div className="position-relative my-4">
              <div className="d-flex justify-content-center align-items-center m-auto">
                <img
                  src={
                    selectedImage ||
                    (state?.image && imageUrl + state?.image) ||
                    "/images/png/duser.png"
                  }
                  onClick={handleImageClick}
                  className="editprofileImage cursor-pointer"
                  alt=""
                />
              </div>

              <input
                type="file"
                accept="image/*"
                name="lecture_image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />

              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt=""
              />
            </div>
          </Row>
          <Row>
            <Col md={12}>
              <label>Name</label>
              <input
                className="form-control px-3"
                placeholder="Name"
                type="text"
                name="name"
                value={state?.name || ""}
                onChange={handleChange}
              />
              {errors?.name && (
                <div className="d-flex ">
                  <SingleError error={errors.name} />
                </div>
              )}
            </Col>
            <Col md={12}>
              <label>Description</label>
              <textarea
                rows={3}
                className="form-control px-3"
                placeholder="Description"
                type="text"
                name="description"
                value={state?.description || ""}
                onChange={handleChange}
              />
              {errors?.description && (
                <div className="d-flex ">
                  <SingleError error={errors.description} />
                </div>
              )}
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${
                Boolean(loading) && "btnDisable"
              }`}
              disabled={Boolean(loading)}
              onClick={handleFormSubmit}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={imageModel}
        onHide={() => setImageModel(false)}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="mt-2" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent ctmInput">
          {currentImage && (
            <img
              className="rounded-3 community-modal"
              src={currentImage}
              alt="Modal Image"
            />
          )}
        </Modal.Body>
      </Modal>

      {/* Delete user modal */}
      <Modal
        Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete Channel</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28 fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button
              className="modalDeleteBtn border-0"
              onClick={() => deleteItem(selectedItem?._id)}
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChannelContent;
