import React, { useState,useCallback,useEffect } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import TablePagination from "./TablePagination";
import { debounce } from "lodash";
import api from "../helper/api";
import { getServerURL, getImageURL } from "../helper/envConfig";
import { toast } from "react-toastify";
const UserTable = ({
  temp,
  search,
  searchTemp,
  setSearch,
  setSearchTemp,
}) => {
  const [viewModel, setViewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [channelList, setChannelList] = useState([]);
  const [mainLoader, setMainLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [paginationData, setPaginationData] = useState({});
  const viewModelOpenFun = (user) => {
    setSelectedUser(user);
    setViewModel(true);
  };
  
  const viewModelCloseFun = () => setViewModel(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const editModelOpenFun = () => setEditModel(true);
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = (userId) => {
    setSelectedUserId(userId);
    setDeleteModel(true);
  };
  const deleteModelCloseFun = () => {
    setDeleteModel(false);
    setSelectedUserId(null); // Clear the user ID
  };
  const serverURL = getServerURL();
  const [id, setId] = useState(null);
  const fetchUsers = useCallback(() => {
    setMainLoader(true);
    api.getWithToken(`${serverURL}get-user?page=${page}&limit=${limit}&search=${search}`)
      .then(response => {
        if (response?.data?.success) {
          const newChannelList = response.data.data || [];
          setChannelList(newChannelList);
          setPaginationData(response?.data?.pagination || {});
          if (newChannelList.length === 0 && page > 1) {
            setPage(page - 1); // Adjust page if no results
          } else if (newChannelList.length > 0) {
            setId(newChannelList[0]._id); // Set ID of the first user
          }
        } else {
          setChannelList([]);
        }
      })
      .catch(error => {
        console.error("Error fetching users:", error.response ? error.response.data : error.message);
      })
      .finally(() => {
        setMainLoader(false);
      });
  }, [serverURL, page, limit, search]);

  // Debounce the fetchUsers call


  useEffect(() => {
    fetchUsers()
  }, [search, page, limit, refreshData, temp]);
  const deleteItem = async (itemId) => {
    try {
      const response = await api.deleteWithToken(
        `${serverURL}delete-user?id=${itemId}`
      );
      if (channelList.length === 1 && paginationData?.totalPages > 1 && page > 1) {
        setPage(prevPage => prevPage - 1);
      } else {
        await fetchUsers();
      }
      await fetchUsers(); 
      deleteModelCloseFun();
      const successMessage =
        typeof response?.data?.data?.message === "string"
          ? response?.data?.data?.message
          : "User deleted successfully.";
      toast.success(successMessage);
    } catch (error) {
      console.error("Error deleting item:", error.response || error.message);
    }
  };
  const imagepath = getImageURL();
  const handleStatusChange = async (user) => {
    const newStatus = user.is_active === 1 ? 0 : 1;
    try {
      await api.patchWithToken(`${serverURL}edit-user?id=${user._id}`, { is_active: newStatus });
      setChannelList(prevList =>
        prevList.map(u =>
          u._id === user._id ? { ...u, is_active: newStatus } : u
        )
      );
      toast.success(`User ${newStatus === 1 ? 'activated' : 'deactivated'} successfully.`);
    } catch (error) {
      console.error("Error updating user status:", error.response || error.message);
    }
  };
  
  return (
    <>
      <Row className="mb-4">
        <Col md={6} lg={5} xl={7}>
          <h1 className="title mb-0">User Details</h1>
        </Col>
        <Col md={6} lg={7} xl={5}>
          <div className="form-control d-flex align-items-center px-3">
                  <img src="/images/svg/search.svg" />
                  <input
                className="form-control border-0 shadow-none pl-black"
                placeholder="Enter search text here"
                type="text"
                name="search"
                autoComplete="off"
                onChange={(e) => {
                  setSearch(e.target.value);
                  setSearchTemp(true);
                  setPage(1);
                }}
                />
                </div>
        </Col>
      </Row>
      <Col className="ctmTable mt-1"> 
  <Table bordered responsive className="text-center">
    <thead className="tableHeader">
      <tr>
        <th className="number-width border">No</th>
        <th>User Name</th>
        <th>Email ID</th>
        <th>Status</th>
        <th>Created Date</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody className="tableBody">
      {channelList.length > 0 ? (
        channelList.map((user, index) => (
          <tr key={user._id}>
            <td>{(page - 1) * limit + index + 1}</td>
            <td>{user.username}</td>
            <td>{user.email}</td>
            <td>
            <div className="form-switch userTable pt-1">
              <input
                className="form-check-input" style={{cursor:'pointer'}}
                type="checkbox"
                checked={user.is_active === 1}
                onChange={() => handleStatusChange(user)}
              />
           
            </div>
          </td>

          <td>
  {user.created_at ? 
    (() => {
      const date = new Date(user.created_at);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    })() 
    : 'N/A'}
</td>


            <td>
              <div className="d-flex justify-content-center align-items-center gap-1">
                <button
                  className="border-0 bg-transparent"
                  onClick={() => viewModelOpenFun(user)}
                >
                  <img src="/images/svg/show.svg" alt="View" />
                </button>
                <button
                  className="border-0 bg-transparent"
                  onClick={() => deleteModelOpenFun(user._id)}
                >
                  <img src="/images/svg/delete.svg" alt="Delete" />
                </button>
              </div>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="6" className="text-center">No user data available</td>
        </tr>
      )}
    </tbody>
  </Table>
  <div className="d-md-flex justify-content-end align-items-center">
    {Boolean(channelList?.length) && paginationData?.totalPages > 1 && (
      <TablePagination
        currentPage={paginationData?.currentPage}
        totalPages={paginationData?.totalPages}
        onPageChange={(newPage) => {
          setPage(newPage);
        }}
      />
    )}
  </div>
</Col>


      {/* View user modal */}
      <Modal
        size="lg"
        show={viewModel}
        onHide={viewModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">User Details</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
        {selectedUser ? (
      <Row>
        <div className="d-flex justify-content-center">
          <div>
          {selectedUser.profile_image &&
            <Row>
              <div className="position-relative my-4">
                <div className="d-flex justify-content-center align-items-center m-auto">
                  <img
                  
                    src={`${imagepath}${selectedUser.profile_image}`}
                    className="editprofileImage cursor-pointer"
                    alt="Profile"
                  />
                </div>
              </div>
            </Row>
          }
  
            <div className="mb-3">
              <tr>
                <td className="userDetailsLabel">User Name:</td>
                &nbsp;
                <td className="userDetailsText">{`${selectedUser?.username}  ` || 'N/A'}</td>
              </tr>
              <tr>
                <td className="userDetailsLabel">Email:</td>
                &nbsp;
                <td className="userDetailsText">{selectedUser.email}</td>
              </tr>
              <tr>
                <td className="userDetailsLabel">Status:</td>
                &nbsp;
                <td className="userDetailsText">{selectedUser.is_active ? 'Active' : 'Inactive'}</td>
              </tr>
            </div>
          </div>
        </div>
      </Row>
    ) : (
      <p>No user data available</p>
    )}
        </Modal.Body>
      </Modal>

      {/* Edit user modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit User</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Form>
            <Row>
              <div className="position-relative my-4">
                <div className="d-flex justify-content-center align-items-center m-auto">
                  <img
                    src="/images/png/image12.png"
                    className="editprofileImage"
                    style={{ cursor: "pointer" }}
                  />
                </div>

                <input
                  type="file"
                  accept="image/*"
                  name="profile_image"
                  style={{ display: "none" }}
                />

                <img
                  src="/images/svg/camera.svg"
                  className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                  alt=""
                />
              </div>
            </Row>
            <Row>
              <Col md={12}>
                <label>Name</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Name"
                  type="text"
                  name="name"
                  defaultValue={"joghn leo"}
                />
              </Col>
              <Col md={12}>
                <label>Email</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Email"
                  type="text"
                  name="name"
                  defaultValue={"john52@gmail.com"}
                />
              </Col>
              <Col md={12}>
                <label>Contact No</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Contact"
                  type="text"
                  name="contact"
                  defaultValue={"1234569870"}
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-center">
              <button className=" btn-login bg-main text-uppercase border-0 w-75 my-4">
                Save
              </button>
            </div>
          </Form>
          {/* <div className="mx-md-4 border-0 bg-transparent mt-2 mb-3">
            <div className="d-flex justify-content-center text-center mt-4">
              <Button className="modelBtn">Save</Button>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>

      {/* Delete user modal */}
      <Modal
  size="md"
  show={deleteModel}
  onHide={deleteModelCloseFun}
  centered
  className="modal-main info-modal"
>
  <Modal.Header
    className="d-block position-relative border-0 pt-4 px-4"
    closeButton
  >
    <Modal.Title className="text-center">Delete User</Modal.Title>
    <div className="d-flex justify-content-center text-center mt-2">
      <img src="/images/svg/line.svg" alt="Line" />
    </div>
  </Modal.Header>
  <Modal.Body className="mx-4 border-0">
    <p className="fs-28 fw-500">Are you sure you want to delete?</p>
  </Modal.Body>
  <Modal.Footer className="border-0">
    <div className="d-flex m-auto gap-3 pb-3 px-4">
      {/* <button
        className="modalCancelBtn px-5 border-0"
        onClick={deleteModelCloseFun}
      >
        Cancel
      </button> */}
      <button
        className="modalDeleteBtn border-0"
        onClick={() => deleteItem(selectedUserId)} // Replace selectedUserId with actual user ID
        disabled={!selectedUserId}
      >
        Delete
      </button>
    </div>
  </Modal.Footer>
</Modal>
    </>
  );
};

export default UserTable;
